import { DeleteIcon, XMarkIcon } from '@dop-ui/icons/react/dop/24';
import { clsx } from 'clsx';
import * as React from 'react';
import { useTranslation } from '../../../../../shared/lib/i18n/client';
import * as apis from '../../../apis';
import HeaderContainer from '../container';
import { DashboardProps, GadgetApiParams } from '../../../types';
import { useDashboardContext } from '../../../context';
import { Button, IconButton } from '../../../../../shared/ui/button';

import { ChangeIcon, CheckIcon, RemoveIcon } from '@dop-ui/icons/react/dop/16';
import { TextField } from '../../../../../shared/ui/text-field';
import { useMessageDialog } from '../../../../../shared/ui/message-dialog';

const layouts = [
  {
    boxCount: 3,
    layout: 1,
    oldLayout: 1,
  },
  {
    boxCount: 3,
    layout: 2,
    oldLayout: 2,
  },
  {
    boxCount: 3,
    layout: 3,
    oldLayout: 3,
  },
  {
    boxCount: 5,
    layout: 4,
    oldLayout: 1,
  },
];

interface LayoutProps {
  layout: number;
}

export interface Props {
  toggleGadgetSpecs: (bool: boolean) => void;
  isVisibleGadgetSpecs: boolean;
}

export default function ControlToolbar({
  toggleGadgetSpecs = () => undefined,
  isVisibleGadgetSpecs = false,
}: Props) {
  const { t } = useTranslation('component');
  const { confirm } = useMessageDialog();
  const [isTitleEditMode, setIsTitleEditMode] = React.useState(false);
  const context = useDashboardContext();
  const {
    currentDashboard,
    gadgets,
    originGadgets,
    prevDashboard,
    dashboards,
  } = context.state;
  const {
    addDashboards,
    setCurrentDashboard,
    setOriginGadgets,
    setPrevDashboard,
    editDashboard,
    removeDashboard,
    getCompanyDashboard,
    setGadgets,
    setEditMode,
  } = context.actions;

  const [name, setName] = React.useState(currentDashboard?.name || '');
  const orgDashboardRef = React.useRef<DashboardProps | null>(currentDashboard);
  const orgGadgetsRef = React.useRef<GadgetApiParams[]>(gadgets);

  const isActivated = (layout: LayoutProps) => {
    return `${currentDashboard!.layout}` === `${layout.layout}`;
  };

  const nameMinLength = 1;
  const nameMaxLength = 32;
  const [isValidInput, setIsValidInput] = React.useState(true);

  const clear = () => {
    setOriginGadgets([]);
    setPrevDashboard(null);
    orgDashboardRef.current = null;
    orgGadgetsRef.current = [];
  };
  const reset = () => {
    setGadgets(orgGadgetsRef.current);
    setCurrentDashboard(orgDashboardRef.current);
  };

  const handleLayoutClick = (layout: number) => {
    setCurrentDashboard({
      ...currentDashboard!,
      layout,
    });
  };

  const handleDeleteClick = async () => {
    if (!currentDashboard) return;
    if (
      await confirm(
        <div className="w-full">
          <h2 className="mb-3">{t('dashboard.confirm.delete.title')}</h2>
          <p className="mb-2" style={{ whiteSpace: 'pre-line' }}>
            {t('dashboard.confirm.delete.description')}
          </p>
        </div>,
        {
          confirmText: t('dashboard.confirm.button.delete'),
        },
      )
    ) {
      const curDashboardId = currentDashboard.dashboardId;
      if (dashboards.length <= 2) {
        const resp = await apis.updateDashboard({
          dashboardId: currentDashboard.dashboardId,
          name: currentDashboard.name,
          layout: currentDashboard.layout,
          gadgetList: [],
        });
        const updatedDashboard = { ...currentDashboard, ...resp };
        editDashboard(updatedDashboard);
        clear();
        setGadgets([]);
      } else {
        await apis.deleteDashboard(curDashboardId);
        removeDashboard(curDashboardId);
        setCurrentDashboard(getCompanyDashboard() || null);
      }
      setEditMode(false);
    }
  };

  const createDashboard = async () => {
    if (!currentDashboard) return;

    const resp = await apis.createDashboard(
      {
        name: currentDashboard.name,
        layout: currentDashboard.layout,
        gadgetList: gadgets,
      },
      'EMPLOYEE',
    );
    const createdDashboard = {
      ...currentDashboard,
      ...resp,
      // 임시 로직. Response 값에 activated가 false로 내려와서 임시로 추가함
      ...{ activated: true },
    };
    addDashboards(createdDashboard);
    setCurrentDashboard(createdDashboard);
    clear();
    setEditMode(false);
  };

  const updateDashboard = async () => {
    if (!currentDashboard) return;

    const resp = await apis.updateDashboard({
      dashboardId: currentDashboard.dashboardId,
      name: currentDashboard.name,
      layout: currentDashboard.layout,
      gadgetList: gadgets,
    });
    const updatedDashboard = { ...currentDashboard, ...resp };
    editDashboard(updatedDashboard);
    clear();
    setEditMode(false);
  };

  const handleOnClickTitleEdit = () => {
    setIsTitleEditMode(true);
  };

  const handleTitleSaveClick = () => {
    if (!currentDashboard) return;
    if (name.length < nameMinLength || name.length > nameMaxLength) {
      setIsValidInput(false);
      return;
    } else {
      setIsValidInput(true);
    }

    const dashboard = { ...currentDashboard, name };
    editDashboard(dashboard);
    setCurrentDashboard(dashboard);
    setIsTitleEditMode(false);
  };

  const handleTitleCancelClick = () => {
    if (!currentDashboard) return;
    setIsValidInput(true);
    setName(currentDashboard.name);
    setIsTitleEditMode(false);
  };

  const handleSaveClick = async () => {
    if (!currentDashboard) return;
    try {
      if (currentDashboard.dashboardId === -1) {
        await createDashboard();
      } else {
        await updateDashboard();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleResetClick = () => {
    reset();
  };

  const handleCancelClick = async () => {
    if (!currentDashboard) return;
    if (
      await confirm(
        <div className="w-full">
          <h2 className="mb-3">
            {t('dashboard.confirm.delete.cancle.title', { title: name })}
          </h2>
          <p className="mb-2" style={{ whiteSpace: 'pre-line' }}>
            {t('dashboard.confirm.cancle.description')}
          </p>
        </div>,
        {
          confirmText: t('dashboard.confirm.button.cancle.yes'),
          cancelText: t('dashboard.confirm.button.cancle.no'),
        },
      )
    ) {
      setGadgets(originGadgets);
      setCurrentDashboard(prevDashboard);
      setEditMode(false);
    }
  };

  const curDashboardName = currentDashboard ? currentDashboard.name : '';

  return (
    <HeaderContainer>
      <div className="flex items-center w-full">
        <div className="flex items-center gap-x-2 shrink-0">
          {!isTitleEditMode && (
            <>
              <span className="text-[18px] font-semibold text-[#363636]">
                {curDashboardName}
              </span>
              <IconButton
                icon={ChangeIcon}
                size="sm"
                onClick={() => handleOnClickTitleEdit()}
              />
            </>
          )}
          {isTitleEditMode && (
            <div className="flex gap-2">
              <TextField
                // className={styles.TextField}
                size="medium"
                value={name}
                onChangeValue={(value) => setName(value)}
                errorMessage={
                  isValidInput
                    ? ''
                    : t('dashboard.input.validation', {
                        minLength: nameMinLength,
                        maxLength: nameMaxLength,
                      })
                }
              />
              {/* <input
                className="flex h-10 px-[12px] items-center rounded-lg border border-solid border-[#d8d8d8]"
                type="text"
                name="title"
                value={name}
                onChange={handleTitleChange}
              /> */}
              <div className="flex gap-2 items-start">
                <Button
                  className="flex gap-1 !h-[40px]"
                  onClick={handleTitleCancelClick}
                >
                  <XMarkIcon size={16} />
                  <p className="text-[12px] text-[#363636]">취소</p>
                </Button>

                <Button
                  className="flex gap-1 !h-[40px]"
                  onClick={handleTitleSaveClick}
                >
                  <CheckIcon size={16} />
                  <p className="text-[12px] text-[#363636]">완료</p>
                </Button>
              </div>
            </div>
          )}
        </div>

        <ul className="option_layout grow flex justify-center items-center">
          {layouts.map((layout) => (
            <li
              key={`layout-${layout.boxCount}-${layout.oldLayout}`}
              className={clsx({ on: isActivated(layout) })}
              onClick={() => handleLayoutClick(layout.layout)}
              aria-hidden="true"
            >
              <span
                className={`ic_dashboard2 ic_layout${layout.boxCount}_${layout.oldLayout}`}
              ></span>
            </li>
          ))}
        </ul>
        <div className="flex items-center gap-x-3">
          {/* <Button
            onClick={handleResetClick}
            size="md"
            variant="outline"
            colorset="level2"
            shape="rect"
          >
            {t('초기화')}
          </Button> */}
          {currentDashboard?.deletable && (
            <Button
              className="flex gap-1"
              size="md"
              shape="round"
              variant="outline"
              colorset="level2"
              onClick={() => void handleDeleteClick()}
            >
              <DeleteIcon size={20} />
              {t('dashboard.delete')}
            </Button>
          )}
          <Button
            className="flex gap-1"
            onClick={() => void handleCancelClick()}
            size="md"
            shape="round"
            variant="outline"
            colorset="level2"
          >
            <RemoveIcon size={15} />
            {t('dashboard.cancel')}
          </Button>
          <Button
            className="flex gap-1"
            onClick={() => void handleSaveClick()}
            size="md"
            shape="round"
            variant="outline"
            colorset="level2"
          >
            <CheckIcon size={20} />
            {t('dashboard.save')}
          </Button>
          {/* <Button
            title={t('가젯 목록 보기')}
            onClick={() => toggleGadgetSpecs(!isVisibleGadgetSpecs)}
            size="md"
            shape="round"
            variant="ghost"
            colorset="level1"
          >
            <Icon>
              <ChevronDownIcon
                className={isVisibleGadgetSpecs ? 'rotate-180' : undefined}
              />
            </Icon>
          </Button> */}
        </div>
      </div>
    </HeaderContainer>
  );
}
