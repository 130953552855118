import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';

import { useWindowResizeObserver } from '../../../../../../shared/lib/hooks';
import { IconButton } from '../../../../../../shared/ui/button';
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  EarthIcon,
} from '@dop-ui/icons/react/dop/24';
import { clsx } from 'clsx';
import * as Tooltip from '../../../../../../shared/ui/tooltip';
import {
  MAX_SPACE,
  MAX_TAB_LENGTH,
  MIN_SPACE,
  MIN_TAB_LENGTH,
  STANDARD_LENGTH,
  TAB_PADDING,
} from './constants';

interface RootProps {
  listLength: number;
  prevText?: string;
  nextText?: string;
  tabHeight?: number;
  className?: string;
  useTabControll?: boolean;
}

interface ContentProps {
  id: string | number;
  selected: boolean;
  onClick: () => void;
  name: string;
  isShared?: boolean;
}

export const Root = ({
  listLength,
  className,
  children,
  useTabControll = true,
}: PropsWithChildren<RootProps>) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [tabPage, setTabPage] = useState(0);
  const [tabOffset, setTabOffset] = useState(1);
  const [totalTabPage, setTotalTabPage] = useState(0);
  const [tabWidth, setTabWidth] = useState(78);

  const totalHeaderWidth = tabWidth * listLength;

  const hasPrev = tabPage > 0;
  const hasNext = tabPage + 1 < totalTabPage;

  function calculateTabOffset() {
    if (headerRef.current && listLength) {
      const { width } = headerRef.current.getBoundingClientRect();
      const calculatedTabWidth =
        width > STANDARD_LENGTH
          ? MAX_TAB_LENGTH + TAB_PADDING
          : MIN_TAB_LENGTH + TAB_PADDING;
      setTabWidth(calculatedTabWidth);

      const space = width > STANDARD_LENGTH ? MAX_SPACE : MIN_SPACE;

      setTabOffset(Math.floor(width / calculatedTabWidth));

      const quotient = width / calculatedTabWidth;

      let updatingOffset;
      if (width % calculatedTabWidth < space) {
        updatingOffset = Math.floor(quotient) - 1;
      } else {
        updatingOffset = Math.floor(quotient);
      }
      setTabOffset(updatingOffset);

      const updatingTotalPage = Math.ceil(listLength / updatingOffset);

      setTotalTabPage(updatingTotalPage);
      if (tabPage >= updatingTotalPage) {
        setTabPage(updatingTotalPage - 1);
      }
    }
  }

  useEffect(() => {
    calculateTabOffset();
  });

  useWindowResizeObserver(calculateTabOffset, {
    debounced: true,
    debouncedTimeMs: 50,
  });

  function handleChangeTabIndex(command: string) {
    if (command === 'next' && tabPage + 1 < totalTabPage) {
      setTabPage((prev) => prev + 1);
    } else if (command === 'prev' && tabPage > 0) {
      setTabPage((prev) => prev - 1);
    }
  }

  return (
    <div className={clsx(className, styles.Wrapper)} ref={headerRef}>
      {hasPrev && useTabControll && (
        <IconButton
          className={styles.LeftIcon}
          icon={ArrowLeftCircleIcon}
          disabled={tabPage === 0}
          onClick={() => handleChangeTabIndex('prev')}
        />
      )}
      <div
        className={clsx(styles.TabList, { '!ml-0': !useTabControll })}
        style={{ visibility: 'visible', width: tabOffset * tabWidth }}
      >
        <div className="flex" style={{ width: totalHeaderWidth }}>
          <div
            style={{
              transform: `translate(-${tabPage * tabOffset * tabWidth}px, 0px) translateZ(0px)`,
              transition: 'transform .2s ease',
            }}
          >
            <ul className="flex gap-2 m-0 w-full float-left box-border">
              {children}
            </ul>
          </div>
        </div>
      </div>
      {hasNext && useTabControll && (
        <IconButton
          disabled={tabPage + 1 >= totalTabPage}
          className={styles.RightIcon}
          icon={ArrowRightCircleIcon}
          onClick={() => handleChangeTabIndex('next')}
        />
      )}
    </div>
  );
};

export const Content = ({
  id,
  selected,
  name,
  isShared = false,
  onClick,
}: PropsWithChildren<ContentProps>) => {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <button
          key={id}
          className={clsx([styles.Tab], {
            [styles.CurrentTab as string]: selected,
          })}
          onClick={onClick}
        >
          <p className={styles.TabTitle}>
            {isShared && <EarthIcon size={16} className="mr-1" />}
            {name}
          </p>
        </button>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content>{name}</Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
};
